import RAW_ACCOUNT_DATA from "./federal_accounts.json";
import RAW_TXN_AG_SAG_OFF from "./txn_agency_subag_office.json";

export type HierarchyNode_t<T> =
  | {
      type: "path";
      nodeId: number;
      name: string;
      children: HierarchyNode_t<T>[];
    }
  | { type: "leaf"; nodeId: number; name: string; row: T };

type HierarchyIndexNode_t<R> =
  | { type: "path"; index: Record<string, HierarchyIndexNode_t<R>> }
  | { type: "leaf"; row: R };

///////////////////////////////////////////////////////////////////////////////
// Contract Transaction Data

export type TxnAggSagOffRow_t = (typeof RAW_TXN_AG_SAG_OFF.data)[number];

export const TRANSACTION_DATA_AG_SAG_OFF = mkTransactionDataAggSagOff();

function mkTransactionDataAggSagOff(): HierarchyNode_t<TxnAggSagOffRow_t> {
  const index: Record<string, HierarchyIndexNode_t<TxnAggSagOffRow_t>> = {};
  for (const e of RAW_TXN_AG_SAG_OFF.data) {
    const path = [
      e.awarding_toptier_agency_name,
      e.awarding_subtier_agency_name,
      e.awarding_office_name,
    ].filter((p): p is string => p != null && p.length > 0);
    updateHierarchyIndex(index, path, e);
  }

  return buildHierarchy("Federal Transactions", index);
}

///////////////////////////////////////////////////////////////////////////////
// Federal Account Data

export type AccountRow_t = (typeof RAW_ACCOUNT_DATA)[number];

export const ACCOUNT_DATA = mkAccountData();

function mkAccountData(): Extract<
  HierarchyNode_t<AccountRow_t>,
  { type: "path" }
> {
  const index: Record<string, HierarchyIndexNode_t<AccountRow_t>> = {};
  for (let idx = 0; idx < RAW_ACCOUNT_DATA.length; ++idx) {
    const e = RAW_ACCOUNT_DATA[idx]!;
    const path = [
      e.reporting_agency_name,
      e.budget_function_title,
      e.budget_bureau_name,
      // e.budget_subfunction_title,
      e.account_name,
    ].filter((v): v is string => v != null);
    updateHierarchyIndex(index, path, { ...e });
  }

  return buildHierarchy("Federal Accounts", index);
}

function buildHierarchy<T>(
  rootName: string,
  index: Record<string, HierarchyIndexNode_t<T>>,
): Extract<HierarchyNode_t<T>, { type: "path" }> {
  let nodeId = 1;
  function helper(index: Record<string, HierarchyIndexNode_t<T>>) {
    const nodes: HierarchyNode_t<T>[] = [];
    for (const [k, v] of Object.entries(index)) {
      if (v.type === "path") {
        const children = helper(v.index);
        nodes.push({ type: "path", nodeId: ++nodeId, name: k, children });
      } else {
        nodes.push({ type: "leaf", nodeId: ++nodeId, name: k, row: v.row });
      }
    }

    if (nodes.length === 1) {
      const node = nodes[0];
      if (node.type === "path") return node.children;
    }
    return nodes;
  }
  return {
    type: "path",
    nodeId: ++nodeId,
    name: rootName,
    children: helper(index),
  };
}

function updateHierarchyIndex<T>(
  index: Record<string, HierarchyIndexNode_t<T>>,
  path: Array<string>,
  row: T,
): void {
  if (!path.length) return;

  const [head, ...rest] = path;
  if (!head) return updateHierarchyIndex(index, rest, row);

  const key = proper(head);
  if (rest.length === 0) {
    index[key] = { type: "leaf", row };
    return;
  }

  const node = index[key];
  if (node?.type === "path") {
    return updateHierarchyIndex(node.index, rest, row);
  }

  const newNode: HierarchyIndexNode_t<T> = { type: "path", index: {} };
  index[key] = newNode;
  return updateHierarchyIndex(newNode.index, rest, row);
}

function proper(s: string): string {
  return s;
}
