import React, { CSSProperties, ChangeEvent } from "react";
import { createWithEqualityFn as createStore } from "zustand/traditional";
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  getSortedRowModel,
  ExpandedState as ExpandedState_t,
  SortingState as SortingState_t,
  ColumnDef as ColumnDef_t,
  Row as Row_t,
  Table as Table_t,
  CellContext as CellContext_t,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  defaultRangeExtractor,
  useVirtualizer,
  Range as Range_t,
  VirtualItem,
  Virtualizer,
} from "@tanstack/react-virtual";
import styled from "styled-components";
import { RxCrossCircled, RxMagnifyingGlass } from "react-icons/rx";
import { IconType } from "react-icons";
import { debounce, noop } from "lodash";

import { Cube } from "./cube";
import { AccountRow_t, ACCOUNT_DATA, HierarchyNode_t } from "./data";

type DataNode_t = HierarchyNode_t<AccountRow_t>;

const CURRENCY_FMT = new Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});
const PERCENT_FMT = new Intl.NumberFormat("en", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const EMPTY_ARRAY: any[] = [];

const ESTIMATED_REVENUES = 5_163_000_000_000;
const OVERSCAN_COUNT = 20;

const CUBE_ACCESSOR_FNS = {
  outlay: (r: AccountRow_t) => r.fy_appropriated,
};

type Cube_t = Cube<AccountRow_t, typeof CUBE_ACCESSOR_FNS>;

interface DataModel_t {
  cube: Cube_t;
  v: number;

  toggleExclude: (node: DataNode_t) => void;
  setAdjustment: (node: DataNode_t, adj: number | null) => void;

  isExcluded: (node: DataNode_t) => boolean;
  isAdjusted: (node: DataNode_t) => boolean;
  adjustment: (node: DataNode_t) => number | null;
}

const CUBE = createStore<DataModel_t>((set, get) => ({
  cube: new Cube(ACCOUNT_DATA.children, CUBE_ACCESSOR_FNS),
  v: 1,

  toggleExclude(node) {
    set(({ cube, v }) => {
      cube.toggleExclude(node);
      return { v: v + 1 };
    });
  },
  setAdjustment(node, adj) {
    set(({ cube, v }) => {
      cube.setAdjustment(node, adj);
      return { v: v + 1 };
    });
  },

  adjustment(node) {
    return get().cube.adjustments.get(node.nodeId) ?? null;
  },
  isExcluded(node) {
    return get().cube.excluded.has(node.nodeId);
  },
  isAdjusted(node) {
    return get().cube.adjustments.get(node.nodeId) != null;
  },
}));

interface SearchModel_t {
  query: string;
  setQuery: (s: string) => void;
}

const SEARCH = createStore<SearchModel_t>((set) => ({
  query: "",
  setQuery: (query: string) => set({ query }),
}));

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-width: 832px;
  padding: 16px 16px 0 16px;
  margin: 0 auto;
`;

const Table = styled.div`
  height: 100%;
  width: 100%;
`;

const Thead = styled.div`
  padding-bottom: 4px;
  border-bottom: 1px solid var(--colors-border-strong);
  box-shadow: 0 2px 10px -10px rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 101;
`;
const TrHead = styled.div`
  display: flex;
`;
const Th = styled.div`
  font-size: 14px;
  font-weight: 200;
`;
const Tbody = styled.div`
  height: 100%;
`;
const Td = styled.div`
  overflow: hidden;
`;

const Cell = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
`;

const ItemNameWrapper = styled.div<{ $depth: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-left: ${(p) => 4 + p.$depth * 18}px;
  overflow: hidden;
`;

const ItemNameExpColToggle = styled.div`
  padding-right: 4px;
  cursor: pointer;
`;

const KPITitle = styled.div`
  font-size: 14px;
  font-weight: 200;
  text-align: right;
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--colors-border-strong);
`;

const Input = styled.input.attrs({
  // @ts-expect-error
  "data-1p-ignore": "",
})``;

const NumberInput = styled(Input).attrs({
  type: "text",
  "data-input": "adjustment",
})`
  box-sizing: border-box;
  border: 0;
  text-align: right;
  padding-right: 4px;
  width: 100%;
  height: 100%;
  background-color: inherit;
  outline: none;
  appearance: textfield;

  &:disabled {
    text-decoration: line-through;
  }
`;

const ItemCheckbox = styled(Input).attrs({
  type: "checkbox",
  "data-allow-capture": true,
})`
  margin: 0;
  padding: 0;
  cursor: pointer;

  &[data-state="indeterminate"] {
    accent-color: var(--colors-data-green);
  }
`;

export default function TransactionCube() {
  const columns = React.useMemo<ColumnDef_t<DataNode_t>[]>(
    () => [
      {
        id: "name",
        accessorFn: (v) => v.name,
        header: () => <NameHeader />,
        cell: (v) => <ItemNameContainer v={v} />,
        filterFn: "includesString",
        size: 565,
        flex: true,
      },
      {
        id: "value",
        accessorFn: (v) => CUBE.getState().cube.value(v, "outlay"),
        cell: (v) => <ValueDisplay v={v} adjusted />,
        header: () => (
          <div style={{ textAlign: "right" }}>FY25 Appropriation</div>
        ),
        sortDescFirst: true,
        size: 180,
      },
      {
        id: "adjustment",
        cell: (v) => <AdjustmentContainer v={v} />,
        header: () => <div style={{ textAlign: "right" }}>Adjustment</div>,
        size: 100,
      },
      {
        id: "gutter",
        cell: () => null,
        header: () => null,
        size: 15,
      },
    ],
    [],
  );

  const [expanded, setExpanded] = React.useState<ExpandedState_t>({});
  const [sorting] = React.useState<SortingState_t>([
    { id: "value", desc: true },
  ]);

  const [rowSelection, setRowSelection] = React.useState({});

  const data = CUBE(({ cube }) => cube.data);

  const flatData = React.useMemo(() => {
    function helper(node: DataNode_t) {
      if (node.type === "leaf") {
        flatData.push(node);
      } else {
        for (const c of node.children) helper(c);
      }
    }

    const flatData: DataNode_t[] = [];
    for (const node of data) helper(node);
    return flatData;
  }, [data]);

  const searchQuery = SEARCH(({ query }) =>
    query.length > 0 ? query : undefined,
  );
  const columnFilters = React.useMemo(
    () => (searchQuery != null ? [{ id: "name", value: searchQuery }] : []),
    [searchQuery],
  );

  const table = useReactTable({
    data: searchQuery != null ? flatData : data,
    columns,
    state: {
      columnFilters,
      expanded,
      sorting,
      rowSelection,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => (row.type === "path" ? row.children : EMPTY_ARRAY),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,

    debugTable: true,
  });

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "flex-end",
          marginBottom: "32px",
        }}
      >
        <div
          style={{
            flex: "1 1 440px",
            fontSize: "32px",
            fontWeight: 800,
          }}
        >
          FY25 Federal Budget
          <br />
          Calculator
        </div>
        <SummaryContainer />
      </div>
      <SearchBar />
      <TableContainer table={table} />
    </PageContainer>
  );
}

function NameHeader() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ textAlign: "left" }}>
        Agency / Budget / Bureau / Account Name
      </div>
    </div>
  );
}

function SummaryContainer() {
  const estDeficit =
    ESTIMATED_REVENUES - CUBE(({ cube }) => cube.globalValue("outlay"));
  const adjDeficit =
    ESTIMATED_REVENUES - CUBE(({ cube }) => cube.globalAdjusted("outlay"));

  return (
    <div
      style={{
        flex: "0 0 360px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        height: "60px",
      }}
    >
      <SummaryKPI name={"FY25 Revenues (est.)"} value={ESTIMATED_REVENUES} />
      <SummaryKPI
        name={"Adjusted Deficit"}
        value={adjDeficit}
        compare={estDeficit}
        valueColor={
          adjDeficit >= 0
            ? "var(--colors-data-green)"
            : "var(--colors-data-red)"
        }
      />
    </div>
  );
}

interface SummaryKPIProps_t {
  name: string;
  value: number;
  valueColor?: CSSProperties["color"];
  compare?: number;
}

function SummaryKPI(props: SummaryKPIProps_t) {
  const cmp =
    props.compare != null
      ? (props.value - props.compare) / props.compare
      : null;
  return (
    <div style={{ width: "170px", marginLeft: "10px" }}>
      <KPITitle>{props.name}</KPITitle>
      <div style={{ fontSize: "14px", marginTop: "8px" }}>
        <NumberDisplay
          value={props.value}
          fmt={CURRENCY_FMT}
          color={props.valueColor}
        />
      </div>
      {cmp != null ? (
        <div
          style={{
            fontSize: "14px",
            marginTop: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <NumberDisplay
            value={Math.abs(cmp)}
            fmt={PERCENT_FMT}
            color={props.valueColor}
          />
          <div
            style={{
              fontSize: "12px",
              marginLeft: "3px",
              color: props.valueColor,
            }}
          >
            {"▼"}
          </div>
        </div>
      ) : null}
    </div>
  );
}

interface TableContainerProps_t {
  table: Table_t<DataNode_t>;
}

function TableContainer(props: TableContainerProps_t) {
  return (
    <Table>
      <Thead>
        {props.table.getHeaderGroups().map((headerGroup) => (
          <TrHead key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <Th
                  key={header.id}
                  style={{
                    flex: `${header.column.getIsFirstColumn() ? "1 1" : "0 0"} ${header.getSize()}px`,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                </Th>
              );
            })}
          </TrHead>
        ))}
      </Thead>
      <Tbody>
        <RowRenderer table={props.table} />
      </Tbody>
    </Table>
  );
}

interface ItemNameContainerProps_t {
  v: CellContext_t<DataNode_t, unknown>;
}

function ItemNameContainer(props: ItemNameContainerProps_t) {
  const onClickExpand = React.useCallback(() => {
    props.v.row.toggleExpanded();
    // @ts-expect-error
    props.v?.scrollTo();
  }, [props.v]);

  const onToggleCheckbox = React.useCallback(() => {
    CUBE.getState().toggleExclude(props.v.row.original);
  }, [props.v.row.original]);

  const state = CUBE((state) => {
    const row = props.v.row;
    if (state.isExcluded(row.original)) {
      return "unchecked";
    }

    const stack = row.subRows.slice(0);
    while (stack.length) {
      const e = stack.pop()!;
      if (state.isExcluded(e.original) || state.isAdjusted(e.original)) {
        return "indeterminate";
      }
      stack.push(...e.subRows);
    }
    return "checked";
  });
  const disabled = CUBE((state) => {
    return props.v.row
      .getParentRows()
      .some((r) => state.isExcluded(r.original));
  });

  const accountCode =
    props.v.row.original.type === "leaf"
      ? props.v.row.original.row.account_code
      : null;

  const accountLink = accountCode
    ? `https://www.usaspending.gov/federal_account/${accountCode}`
    : null;

  return (
    <Cell>
      <ItemNameWrapper $depth={props.v.row.depth}>
        {props.v.row.getCanExpand() ? (
          <ItemNameExpColToggle
            onClick={(e) => {
              e.preventDefault();
              onClickExpand();
            }}
            style={{
              fontSize: "18px",
              margin: 0,
              padding: 0,
              height: "100%",
              marginRight: "4px",
              alignContent: "center",
              userSelect: "none",
              rotate: props.v.row.getIsExpanded() ? "90deg" : "0deg",
            }}
          >
            {"▶"}
          </ItemNameExpColToggle>
        ) : null}
        <div
          style={{
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflowY: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {accountLink ? (
            <a
              target="_blank"
              rel="noreferrer"
              title={props.v.getValue<string>()}
              style={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              href={accountLink}
            >
              {props.v.getValue<string>()}
            </a>
          ) : (
            props.v.getValue<string>()
          )}
        </div>
      </ItemNameWrapper>
    </Cell>
  );
}

interface AdjustmentContainerProps_t {
  v: CellContext_t<DataNode_t, unknown>;
}

function AdjustmentContainer(props: AdjustmentContainerProps_t) {
  const adjustment = CUBE((state) => state.adjustment(props.v.row.original));
  const disabled = CUBE((state) => {
    const rows = [props.v.row, ...props.v.row.getParentRows()];
    return rows.some((r) => state.isExcluded(r.original));
  });

  const [value, setValue] = React.useState(
    adjustment != null ? adjustment : "",
  );

  const ref = React.useRef<HTMLInputElement | null>(null);

  const onChange = React.useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => setValue(ev.target.value),
    [],
  );

  const onKeyDown = React.useCallback(
    (ev: React.KeyboardEvent) => {
      const cube = CUBE.getState();
      if (ev.code === "Escape") {
        const adj = cube.adjustment(props.v.row.original);
        setValue(adj ?? "");

        ref.current?.blur();
      } else if (ev.code === "Tab") {
        ref.current?.blur();
      } else if (ev.code === "Enter") {
        ref.current?.blur();

        window.document.body.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "ArrowDown",
            code: "ArrowDown",
            charCode: 0,
            keyCode: 40,
            which: 40,
            shiftKey: false,
          }),
        );
      }
    },
    [props.v.row.original],
  );

  const onBlur = React.useCallback(() => {
    const cube = CUBE.getState();
    if (value === "") {
      cube.setAdjustment(props.v.row.original, null);
      setValue("");
    } else {
      const n = Number(value);
      if (n >= 100 || n < 0) {
        cube.setAdjustment(props.v.row.original, null);
        setValue("");
      } else if (Number.isFinite(n)) {
        cube.setAdjustment(props.v.row.original, n);
      } else {
        const adj = cube.adjustment(props.v.row.original);
        setValue(adj ?? "");
      }
    }
  }, [props.v.row.original, value]);

  React.useEffect(() => {
    const input = ref.current;
    if (!input) return;

    const reset = () => {
      CUBE.getState().setAdjustment(props.v.row.original, null);
      setValue("");
    };
    input.addEventListener("change:adjustment:reset", reset);
    return () => input.removeEventListener("change:adjustment:reset", reset);
  }, [props.v.row.original]);

  return (
    <Cell>
      <NumberInput
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        placeholder="100"
        disabled={disabled}
      />
      <div>%</div>
    </Cell>
  );
}

interface RowRendererProps_t {
  table: Table_t<DataNode_t>;
}

function RowRenderer(props: RowRendererProps_t) {
  const expanded = props.table.getState().expanded;
  const rowModel = props.table.getRowModel();

  const expandedIndexes = React.useMemo(() => {
    const ids = new Set(Object.keys(expanded));
    const indexes: Record<string, number> = {};
    for (let idx = 0; idx < rowModel.rows.length; idx++) {
      const row = rowModel.rows[idx];
      if (!(row && ids.has(row.id))) continue;
      indexes[row.id] = idx;
    }
    return indexes;
  }, [rowModel.rows, expanded]);

  const rangeExtractor = React.useCallback(
    (range: Range_t) => {
      const firstRow = rowModel.rows[range.startIndex];

      const parents: number[] = [];
      for (const parent of firstRow.getParentRows()) {
        const idx = expandedIndexes[parent.id];
        if (idx != null) parents.push(idx);
      }

      const next = new Set([...parents, ...defaultRangeExtractor(range)]);

      return Array.from(next).sort((a, b) => a - b);
    },
    [rowModel.rows, expandedIndexes],
  );

  const parentRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rowModel.rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: OVERSCAN_COUNT,
    rangeExtractor,
  });

  const elems = [];

  const vrows = virtualizer.getVirtualItems();
  for (let index = 0; index < vrows.length; index++) {
    const vrow = vrows[index];
    const row = rowModel.rows[vrow.index];
    if (!row || !vrow) continue;

    let sticky = false;
    if (row.getIsExpanded()) {
      const offset = (virtualizer.scrollOffset ?? 0) + row.depth * 40;
      const item = virtualizer.getVirtualItemForOffset(offset);
      sticky = item != null && vrow.index <= item.index;
    }

    elems.push(
      <RowContainer
        key={row.id}
        virtualizer={virtualizer}
        index={index}
        row={row}
        vrow={vrow}
        sticky={sticky}
      />,
    );
  }

  const rect = parentRef.current?.getBoundingClientRect();

  const onKeyDown = React.useCallback(
    (ev: KeyboardEvent) => {
      if (skipKeyDownCapture(ev)) return;

      const rowSelection = props.table.getState().rowSelection;

      switch (ev.code) {
        case "ArrowDown":
        case "ArrowUp": {
          ev.preventDefault();

          const rid = Object.keys(rowSelection)[0] ?? "";
          const next = siblingRowFromRowId(
            rid,
            ev.code === "ArrowDown" ? "next" : "prev",
            ev.metaKey ? 5 : 1,
          );

          if (!next) {
            const vrow = virtualizer.getVirtualItemForOffset(
              virtualizer.scrollOffset ?? 0,
            );
            const row = vrow
              ? props.table.getRowModel().rows[vrow.index]
              : null;
            if (vrow && row) {
              props.table.setRowSelection({ [row.id]: true });
              const offset = ev.code === "ArrowUp" ? -row.depth : row.depth;
              virtualizer.scrollToIndex(vrow.index + offset, {
                align: "auto",
                behavior: "smooth",
              });
            }
            return;
          }

          if (!(next instanceof Element)) return;
          const nextId = next?.attributes.getNamedItem("data-row-id");
          if (!nextId) return;
          props.table.setRowSelection({ [nextId.value]: true });

          const nextIndex = Number(
            next?.attributes.getNamedItem("data-index")?.value,
          );
          if (Number.isInteger(nextIndex)) {
            const row = props.table.getRow(nextId.value);
            const offset = ev.code === "ArrowUp" ? -row.depth : row.depth;
            virtualizer.scrollToIndex(nextIndex + offset, {
              align: "auto",
              behavior: "smooth",
            });
          }

          if (next instanceof HTMLElement) next.focus();
          break;
        }

        case "ArrowRight":
        case "ArrowLeft": {
          ev.preventDefault();

          const rid = Object.keys(rowSelection)[0];
          const row = rid ? props.table.getRow(rid) : null;
          if (!row) return;

          if (ev.key === "ArrowRight") {
            row.toggleExpanded(true);
          } else if (ev.key === "ArrowLeft") {
            if (row.getIsExpanded()) {
              row.toggleExpanded(false);
            } else {
              row.getParentRow()?.toggleSelected(true);
            }
          }

          const e = window.document.querySelector(`[data-row-id="${rid}"]`);
          if (!(e instanceof Element)) return;

          const index = Number(e.attributes.getNamedItem("data-index")?.value);
          if (Number.isInteger(index)) {
            const row = props.table.getRow(rid);
            const offset = -row.depth;
            virtualizer.scrollToIndex(index + offset, {
              align: "auto",
              behavior: "smooth",
            });
          }

          if (e instanceof HTMLElement) e.focus();
          break;
        }

        case "Space": {
          ev.preventDefault();
          // const rid = Object.keys(rowSelection)[0];
          // const row = rid ? props.table.getRow(rid) : null;
          // if (!row) return;
          // CUBE.getState().toggleExclude(row.original);
          break;
        }

        case "Enter": {
          const rid = Object.keys(rowSelection)[0];
          const row = rid ? props.table.getRow(rid) : null;
          if (!row) return;

          const e = window.document.querySelector(`[data-row-id="${rid}"]`);
          if (!(e instanceof Element)) return;

          const t = e.querySelector('[data-input="adjustment"]');
          if (t instanceof HTMLInputElement) t.focus();

          break;
        }

        case "Backspace": {
          ev.preventDefault();

          const rid = Object.keys(rowSelection)[0];
          const row = rid ? props.table.getRow(rid) : null;
          if (!row) return;

          const e = window.document.querySelector(`[data-row-id="${rid}"]`);
          if (!(e instanceof Element)) return;

          const t = e.querySelector('[data-input="adjustment"]');
          if (t instanceof HTMLInputElement) {
            t.dispatchEvent(new Event("change:adjustment:reset"));
          }

          break;
        }

        case "Digit0":
        case "Digit1":
        case "Digit2":
        case "Digit3":
        case "Digit4":
        case "Digit5":
        case "Digit6":
        case "Digit7":
        case "Digit8":
        case "Digit9": {
          const rid = Object.keys(rowSelection)[0];
          const row = rid ? props.table.getRow(rid) : null;
          if (!row) return;

          const e = window.document.querySelector(`[data-row-id="${rid}"]`);
          if (!(e instanceof Element)) return;

          const t = e.querySelector('[data-input="adjustment"]');
          if (t instanceof HTMLInputElement) {
            t.dispatchEvent(new Event("change:adjustment:reset"));
            t.focus();
          }

          break;
        }

        default:
          return;
      }
    },
    [props.table, virtualizer],
  );

  React.useEffect(() => {
    window.document.body.addEventListener("keydown", onKeyDown);
    return () => window.document.body.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <div
      ref={parentRef}
      tabIndex={-1}
      style={{
        height: `${rect?.top ? Math.floor(window.innerHeight - rect.top - 1) : 1}px`,
        overflow: "auto",
        opacity: rect ? 1 : 0,
        width: "100%",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {elems}
      </div>
    </div>
  );
}

function siblingRowFromRowId(
  rid: string,
  dir: "prev" | "next",
  step: number,
): Element | null {
  if (rid.length === 0) return null;

  const e = window.document.querySelector(`[data-row-id="${rid}"]`);

  let next: Element | null = e;
  do {
    const sib = dir === "next" ? next?.nextSibling : next?.previousSibling;
    next = sib instanceof Element ? sib : null;
  } while (--step && next != null);

  return next;
}

interface RowContainerProps_t {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  index: number;
  vrow: VirtualItem;
  row: Row_t<DataNode_t>;
  sticky?: boolean;
}

function RowContainer(props: RowContainerProps_t) {
  const rowTranslate = props.vrow.start - props.index * props.vrow.size;

  const scrollTo = React.useCallback(() => {
    props.virtualizer.scrollToIndex(props.vrow.index - props.row.depth, {
      align: "auto",
      behavior: "smooth",
    });
  }, [props.virtualizer, props.row, props.vrow]);

  return (
    <div
      tabIndex={-2}
      data-index={props.vrow.index}
      data-row-id={props.row.id}
      onClick={() => props.row.toggleSelected(true)}
      onFocus={() => props.row.toggleSelected(true)}
      style={{
        outline: 0,
        backgroundColor: props.row.getIsSelected() ? "#f7f7f7" : "#ffffff",
        display: "flex",
        flexDirection: "row",
        height: `${props.vrow.size}px`,
        borderBottom: "1px solid var(--colors-border)",
        left: 0,
        width: "100%",
        minWidth: "800px",
        ...(props.sticky
          ? {
              position: "sticky",
              top: props.row.depth * props.vrow.size,
              zIndex: 99 - 2 * props.row.depth,
            }
          : {
              position: "relative",
              transform: `translateY(${rowTranslate}px)`,
              zIndex: 100 - 2 * props.row.depth,
            }),
      }}
    >
      {props.row.getVisibleCells().map((cell) => {
        return (
          <Td
            key={cell.id}
            style={{
              flex: `${cell.column.getIsFirstColumn() ? "1 1" : "0 0"} ${cell.column.getSize()}px`,
            }}
          >
            {flexRender(cell.column.columnDef.cell, {
              scrollTo,
              ...cell.getContext(),
            })}
          </Td>
        );
      })}
    </div>
  );
}

interface TxnCheckboxProps_t {
  row: Row_t<DataNode_t>;
  state: "checked" | "unchecked" | "indeterminate";
  disabled: boolean;
  toggle: () => void;
}

function TxnCheckbox(props: TxnCheckboxProps_t) {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!ref.current) return;

    ref.current.checked = props.state === "checked";
    ref.current.indeterminate =
      props.disabled || props.state === "indeterminate";
    ref.current.disabled = props.disabled;
  }, [props.state, props.disabled]);

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        margin: "0 8px",
      }}
    >
      <ItemCheckbox ref={ref} onClick={props.toggle} data-state={props.state} />
    </div>
  );
}

function ValueDisplay(props: {
  v: CellContext_t<DataNode_t, unknown>;
  adjusted?: true;
}) {
  const data = props.v.row.original;
  const { value, adjusted } = CUBE(({ cube }) => ({
    value: cube.value(data, "outlay"),
    adjusted: cube.adjusted(data, "outlay"),
  }));

  const isAdjusted = props.adjusted && value !== adjusted;

  return (
    <Cell title={isAdjusted ? `${CURRENCY_FMT.format(value)}` : undefined}>
      <NumberDisplay
        fmt={CURRENCY_FMT}
        value={props.adjusted ? adjusted : value}
        italics={isAdjusted}
        color={isAdjusted ? "var(--colors-data-green)" : undefined}
      />
    </Cell>
  );
}

function NumberDisplay(props: {
  value: number;
  fmt: Intl.NumberFormat;
  fontSize?: CSSProperties["fontSize"];
  color?: CSSProperties["color"];
  italics?: boolean;
}) {
  return (
    <div
      style={{
        color: props.color,
        fontFamily: "monospace",
        fontSize: props.fontSize,
        fontStyle: props.italics ? "italic" : undefined,
        textAlign: "right",
        whiteSpace: "nowrap",
        width: "100%",
      }}
    >
      {props.fmt.format(props.value)}
    </div>
  );
}

function skipKeyDownCapture(ev: UIEvent): boolean {
  if (!(ev.target instanceof HTMLElement)) return false;

  switch (ev.target.tagName) {
    case "INPUT":
    case "TEXTAREA":
      return ev.target.attributes.getNamedItem("data-allow-capture") == null;

    default:
      return false;
  }
}

function SearchBar() {
  const [query, setQuery] = React.useState("");

  const cancelRef = React.useRef(noop);
  const onChange = React.useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setQuery(ev.target.value);

      const fn = debounce(
        () => SEARCH.setState({ query: ev.target.value }),
        100,
      );

      if (cancelRef.current) cancelRef.current();
      cancelRef.current = () => fn.cancel();
      fn();
    },
    [query],
  );
  const onClear = React.useCallback(() => {
    setQuery("");

    if (cancelRef.current) cancelRef.current();
    SEARCH.setState({ query: "" });
  }, []);

  return (
    <div style={{ marginBottom: "16px" }}>
      <div
        style={{
          padding: "4px 0",
          position: "relative",
          width: "100%",
          opacity: 1,
        }}
      >
        <div
          onClick={onClear}
          style={{
            position: "absolute",
            top: "6px",
            left: "0px",
          }}
        >
          <label
            style={{
              cursor: "pointer",
            }}
            htmlFor="search"
          >
            <Icon
              icon={query.length > 0 ? RxCrossCircled : RxMagnifyingGlass}
              size="20px"
            />
          </label>
        </div>
        <Input
          id="search"
          placeholder="Search federal accounts"
          value={query}
          onChange={onChange}
          style={{
            fontSize: "14px",
            background: "none",
            height: "24px",
            textIndent: "24px",
            width: "100%",
            border: "none",
            outline: "none",
          }}
        />
      </div>
    </div>
  );
}

interface IconProps_t {
  icon: IconType;
  color?: string;
  title?: string;
  size?: string;
  onClick?: () => void;
}

function Icon(props: IconProps_t) {
  const { icon, ...rest } = props;
  return (
    <div
      style={{
        height: props.size,
        width: props.size,
      }}
    >
      {props.icon({ ...rest, style: { width: "100%", height: "100%" } })}
    </div>
  );
}
