import { HierarchyNode_t } from "./data";

export type AccessorFn_t<T> = (row: T) => number;

type NodeIndex_t = Record<string, number>;

interface IndexEntry_t {
  values: NodeIndex_t;
  adjusted: NodeIndex_t;
}

export class Cube<T, A extends Record<string, AccessorFn_t<T>>> {
  private fns: A;
  private index = new Map<HierarchyNode_t<T>, IndexEntry_t>();
  private globalEntry: IndexEntry_t = { values: {}, adjusted: {} };

  readonly excluded: Set<number> = new Set();
  readonly adjustments: Map<number, number | null> = new Map();
  readonly data: HierarchyNode_t<T>[];

  constructor(data: HierarchyNode_t<T>[], fns: A) {
    this.data = data;
    this.fns = fns;

    this.rebuildIndex();
  }

  globalValue<K extends keyof A>(key: K): number {
    return this.globalEntry.values[key as string] ?? 0;
  }
  globalAdjusted<K extends keyof A>(key: K): number {
    return this.globalEntry.adjusted[key as string] ?? 0;
  }

  value<K extends keyof A>(row: HierarchyNode_t<T>, key: K): number {
    const e = this.index.get(row);
    return e ? (e.values[key as string] ?? 0) : 0;
  }
  adjusted<K extends keyof A>(row: HierarchyNode_t<T>, key: K): number {
    const e = this.index.get(row);
    return e ? (e.adjusted[key as string] ?? 0) : 0;
  }

  toggleExclude(node: HierarchyNode_t<T>): void {
    if (this.excluded.has(node.nodeId)) {
      this.excluded.delete(node.nodeId);
    } else {
      this.excluded.add(node.nodeId);
    }
    this.rebuildIndex();
  }
  setAdjustment(node: HierarchyNode_t<T>, v: number | null): void {
    this.adjustments.set(node.nodeId, v);
    this.rebuildIndex();
  }

  private rebuildIndex(): void {
    this.index.clear();
    this.globalEntry = { values: {}, adjusted: {} };

    for (const node of this.data) {
      const index = this.indexRow(node);
      for (const key of Object.keys(this.fns)) {
        if (!this.excluded.has(node.nodeId)) {
          Cube.updateEntry(
            this.globalEntry.adjusted,
            key,
            index.adjusted[key] ?? 0,
          );
        }
        Cube.updateEntry(this.globalEntry.values, key, index.values[key] ?? 0);
      }
    }
  }
  private indexRow(node: HierarchyNode_t<T>): IndexEntry_t {
    const entry = this.index.get(node);
    if (entry) return entry;

    const newEntry: IndexEntry_t = { values: {}, adjusted: {} };
    if (node.type === "leaf") {
      for (const [key, fn] of Object.entries(this.fns)) {
        const v = fn(node.row);
        const adj = this.adjustment(node.nodeId);
        Cube.updateEntry(newEntry.adjusted, key, v * adj);
        Cube.updateEntry(newEntry.values, key, v);
      }
    } else if (node.type === "path") {
      for (const child of node.children) {
        const childIndex = this.indexRow(child);
        for (const key of Object.keys(this.fns)) {
          const adj = this.adjustment(node.nodeId);
          Cube.updateEntry(
            newEntry.adjusted,
            key,
            (childIndex.adjusted[key] ?? 0) * adj,
          );
          Cube.updateEntry(newEntry.values, key, childIndex.values[key] ?? 0);
        }
      }
    }

    this.index.set(node, newEntry);
    return newEntry;
  }

  private adjustment(nodeId: number): number {
    if (this.excluded.has(nodeId)) return 0;

    const adj = this.adjustments.get(nodeId);
    return adj != null ? adj / 100 : 1;
  }

  private static updateEntry(
    index: NodeIndex_t,
    key: string,
    val: number,
  ): void {
    index[key] = (index[key] ?? 0) + val;
  }
}
